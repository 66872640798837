export function sanitizeFileNameForContentHeader(fileName) {
  // Regular expression to match invalid characters
  let output = fileName;
  const invalidCharsRegex = /[^a-zA-Z0-9!&'()+-._]/g;

  //Replace åäöÅÄÖ
  const replacements = [
    { from: 'ä', to: 'a' },
    { from: 'å', to: 'a' },
    { from: 'ö', to: 'o' },
    { from: 'Ä', to: 'A' },
    { from: 'Å', to: 'A' },
    { from: 'Ä', to: 'O' },
  ];
  replacements.forEach((repl) => {
    output = output.split(repl.from).join(repl.to);
  });

  // Replace invalid characters with an empty string
  output = output.replace(invalidCharsRegex, '');
  return output;
}
